<template>
  <div class="join">
    <div class="baseBox">
      <div class="joinBox reset">
        <h1>{{ $t("127") }}</h1>
        <h3>{{ $t("128") }}</h3>
        <div class="buttonWrap">
          <button class="point large" @click="updateStatus">
            {{ $t("130") }}
          </button>
          <button class="large" @click="handleCancel">{{ $t("btn-cancel") }}</button>
        </div>
      </div>
      <!-- <Footer /> -->
    </div>
  </div>
</template>
//
<script>
// import Footer from "@/components/Footer";
import { changeStatus } from "@/api/login";
export default {
  // components: { Footer },
  data() {
    return {
      accountId: "",
    };
  },
  created() {
    this.accountId = this.$route.params.accountId;
  },
  methods: {
    handleCancel() {
      localStorage.removeItem("token");
      this.$router.go(-1);
    },
    updateStatus() {
      changeStatus().then((res) => {
        if (res.data.result == 0) {
          localStorage.removeItem("token");
          alert(this.$t("alert-message-dormant-release"));
          this.getWorkerLogin();
        }else{
          alert(this.$t("alert-message-dormant-release-not"));
          this.getWorkerLogin();
        }
      });
    },
    getWorkerLogin(){
      this.$router.go(-1);
    }
  },
};
</script>
